import { useStaticQuery, graphql } from 'gatsby';

export const useNewsData = () => {
  const { allNodeNews } = useStaticQuery(
    graphql`
      query NewsData {
        allNodeNews(sort: { order: DESC, fields: field_date___value }) {
          nodes {
            id
            title
            body {
              processed
              summary
            }
            field_summary_only
            field_date {
              value(formatString: "dddd, MMMM D")
            }
            dateShort: field_date {
              value(formatString: "MM/DD")
            }
            date: field_date {
              full: value(formatString: "dddd, MMMM D")
              medium: value(formatString: "MMMM D, Y")
              time: value(formatString: "h:mma")
              removalTime: end_value
              start: value(formatString: "YYYY-MM-DD")
              end: end_value(formatString: "YYYY-MM-DD")
            }
            field_image {
              imageDerivatives {
                links {
                  w90_1_1 {
                    href
                  }
                  w344_16_9 {
                    href
                  }
                }
              }
              alt
            }
            srcSet: field_image {
              ...newsSrcSet10x7Fragment
              ...newsSrcSet16x9Fragment
            }
            relationships {
              field_tags {
                drupal_id
                name
                relationships {
                  parent {
                    name
                  }
                }
              }
              type: node_type {
                name
              }
            }
            path {
              alias
            }
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const news = allNodeNews.nodes.map((item) => {
    // Grab all the srcset data and creating a plain
    // array of all the image urls.
    const ratio10x7links = item?.srcSet?.ratio10x7?.links ?? {};
    const ratio10x7 = ratio10x7links
      ? Object.keys(ratio10x7links).map((value) => ratio10x7links[value].href)
      : [];
    const ratio16x9links = item?.srcSet?.ratio16x9?.links ?? {};
    const ratio16x9 = ratio16x9links
      ? Object.keys(ratio16x9links).map((value) => ratio16x9links[value].href)
      : [];
    return {
      id: item?.id,
      title: item?.title,
      startDate: item?.field_date?.value,
      dateShort: item?.dateShort?.value,
      // We need additional date data so we can filter out events.
      date: item?.date,
      type: item?.relationships?.type.name,
      summary: item?.body.summary,
      smallImage: {
        alt: item?.field_image?.alt,
        // eslint-disable-next-line camelcase
        src: item?.field_image?.imageDerivatives?.links?.w90_1_1?.href
      },
      largeImage: {
        alt: item?.field_image?.alt,
        // eslint-disable-next-line camelcase
        src: item?.field_image?.imageDerivatives?.links?.w344_16_9?.href
      },
      srcSet: {
        alt: item?.field_image?.alt,
        srcs: {
          ratio10x7,
          ratio16x9
        }
      },
      body: item?.body?.processed,
      // So this is just the _first_ tag in the list since that's all we can display.
      // We may need to redo this later.
      tag: item?.relationships?.field_tags[0]?.name,
      parentTag:
        item?.relationships?.field_tags[0]?.relationships?.parent[0]?.name,
      url: item?.path?.alias,
      cardSummaryOnly: item?.field_summary_only,
      // Preserve the original relationship structure so it's easier to figure out the filterable
      // fields later.
      relationships: item?.relationships
    };
  });

  return news;
};
